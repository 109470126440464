import { useState, useEffect } from "react";

const suggestions = [
  "Toyota Yaris 2018 automático en Heredia",
  "Pickup 4x4 Isuzu",
  "SUV Hyundai eléctrico en Guanacaste",
  "Hilux diesel manual 2001",
];

const FORWARD_TYPING_VELOCITIES = [45, 55, 95, 105];

const BACKWARDS_TYPING_VELOCITIES = [35, 45, 65, 95];

function getForwardVelocity(index) {
  if (index === 0) {
    return FORWARD_TYPING_VELOCITIES[FORWARD_TYPING_VELOCITIES.length - 1];
  }

  return FORWARD_TYPING_VELOCITIES[
    Math.floor(Math.random() * FORWARD_TYPING_VELOCITIES.length)
  ];
}

function getBackwardsVelocity(index, length) {
  if (index === length) {
    return 800;
  }

  return BACKWARDS_TYPING_VELOCITIES[
    Math.floor(Math.random() * BACKWARDS_TYPING_VELOCITIES.length)
  ];
}

export default function usePlaceholderSuggestions() {
  const [currentSuggestion, setCurrentSuggestion] = useState(0);
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState("forward");
  const [animationEnabled, setAnimationEnabled] = useState(true);

  function disableAnimation() {
    setAnimationEnabled(false);
  }

  useEffect(() => {
    let interval;
    if (direction === "forward" && animationEnabled) {
      if (index < suggestions[currentSuggestion].length) {
        interval = setInterval(() => {
          setIndex((index) => index + 1);
        }, getForwardVelocity(index));
      } else {
        setDirection("backwards");
      }
      return () => {
        clearInterval(interval);
      };
    }
  }, [index, currentSuggestion, direction, animationEnabled]);

  useEffect(() => {
    let interval;
    if (direction === "backwards" && animationEnabled) {
      if (index > 0) {
        interval = setInterval(() => {
          setIndex((index) => index - 1);
        }, getBackwardsVelocity(index, suggestions[currentSuggestion].length));
      } else {
        if (currentSuggestion < suggestions.length - 1) {
          setCurrentSuggestion((currentSuggestion) => currentSuggestion + 1);
          setIndex(0);
        } else {
          setCurrentSuggestion(0);
        }

        setDirection("forward");
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [index, currentSuggestion, direction, animationEnabled]);

  return suggestions[currentSuggestion].substring(0, index);
}
