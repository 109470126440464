import { PlusIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { classNames } from "../utils/formatters";

const SellButton = ({
  external,
  responsive,
}: {
  external?: boolean;
  responsive?: boolean;
}) => {
  return (
    <Link href="/vender" target={external ? "_blank" : "_self"}>
      <div className={classNames(responsive ? "flex sm:hidden" : "hidden")}>
        <div className="cursor-pointer bg-white p-1 rounded-full text-gray-600 hover:text-gray-500 focus:outline-none flex flex-col items-center">
          <span className="sr-only">Vender</span>
          <PlusIcon className="h-6 w-6" aria-hidden="true" />
          <span className="text-xxs">Vender</span>
        </div>
      </div>

      <button
        id="sell-button"
        className={classNames(
          "relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 hover:border-orange-600 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500",
          responsive ? "hidden sm:flex" : "flex"
        )}
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        <span>Vender</span>
      </button>
    </Link>
  );
};

export default SellButton;
