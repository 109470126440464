import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { UserIcon } from "@heroicons/react/24/solid";
import Avatar from "./Avatar";
import Link from "next/link";
import { signOut, useSession } from "next-auth/react";
import VerifiedUserIcon from "./VerifiedUserIcon";
import classNames from "classnames";
import { useRouter } from "next/router";
import { SUBSCRIPTION_TYPES } from "../utils/constants";
import Image from "next/image";

interface User {
  company?: { logo?: string };
  image?: string;
  username?: string;
  idVerified?: boolean;
}

export const getUserNavigation = (userParam) =>
  userParam
    ? [
        { name: "Perfil", href: "/profile/edit" },
        {
          name: "Mis Publicaciones",
          href: `/${userParam?.username ? userParam?.username : "/"}`,
        },
        { name: "Mis Compras", href: `/purchases` },
        { name: "Favoritos", href: `/favorites` },
        {
          name: "Cerrar Sesión",
          onClick: () => signOut({ callbackUrl: `${window.location.origin}/` }),
        },
      ]
    : [{ name: "Iniciar Sesión", href: "/auth/signin" }];

const ProfileDropdown = () => {
  const router = useRouter();
  const session = useSession();
  const sessionData = session?.data;
  const user = sessionData?.user;

  const userNavigation = getUserNavigation(user);
  const goToSignIn = () => router.push("/api/auth/signin");

  if (session.status === "loading") {
    return null;
  }

  if (session.status === "unauthenticated")
    return (
      <button
        id="signin-button"
        type="button"
        className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 hover:border-orange-600 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
        onClick={goToSignIn}
      >
        <UserIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        <span>Iniciar sesión</span>
      </button>
    );

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
          <span className="sr-only">Open user menu</span>
          <Avatar
            src={user?.company?.logo || user?.image || ""}
            username={user?.username}
            idVerified={user?.idVerified}
            size="small"
            nav
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {!user?.idVerified ? (
            <Menu.Item>
              {({ active }) => (
                <Link href="/profile/verify" passHref={true}>
                  <span
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "flex px-4 py-2 text-sm text-gray-700",
                      "hover:bg-gray-100 cursor-pointer"
                    )}
                  >
                    Verificar Identidad
                    <VerifiedUserIcon
                      idVerified={false}
                      className="ml-1 h-5 w-5"
                    />
                  </span>
                </Link>
              )}
            </Menu.Item>
          ) : null}

          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) =>
                item.href ? (
                  <Link
                    key={item.name as string}
                    href={item.href}
                    passHref={true}
                  >
                    <span
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700",
                        "hover:bg-gray-100 cursor-pointer"
                      )}
                    >
                      {item.name}
                    </span>
                  </Link>
                ) : (
                  <button
                    key={item.name as string}
                    onClick={item.onClick}
                    className="w-full text-left"
                  >
                    <span
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700",
                        "hover:bg-gray-100 cursor-pointer"
                      )}
                    >
                      {item.name}
                    </span>
                  </button>
                )
              }
            </Menu.Item>
          ))}
          {user?.subscription?.type === SUBSCRIPTION_TYPES.connect ? (
            <Menu.Item>
              {({ active }) => (
                <Link href="/connect" passHref={true}>
                  <div
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 pb-2 pt-3 mt-2 border-t border-t-gray-200",
                      "hover:bg-gray-100 cursor-pointer"
                    )}
                  >
                    <Image
                      src="/connect_logo_h.png"
                      alt="Logo Movi Connect"
                      width={100}
                      height={30}
                    />
                  </div>
                </Link>
              )}
            </Menu.Item>
          ) : null}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileDropdown;
