import { SUBSCRIPTION_STATUSES, SUBSCRIPTION_TYPES } from "./constants";

export function isUnauthorized(user) {
  return (
    !user?.subscription ||
    user?.subscription?.type !== SUBSCRIPTION_TYPES.connect
  );
}

export function isInactive(user) {
  return (
    user?.subscription?.type === SUBSCRIPTION_TYPES.connect &&
    (user?.subscription?.status === SUBSCRIPTION_STATUSES.not_started ||
      user?.subscription?.status === SUBSCRIPTION_STATUSES.cancelled ||
      user?.subscription?.status === SUBSCRIPTION_STATUSES.on_hold)
  );
}

export function calculateFeeAmount(price: number, fee: number) {
  return Number(price) * (Number(fee) / 100);
}
