import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import usePlaceholderSuggestions from "../hooks/usePlaceholderSuggestions";
import { classNames } from "../utils/formatters";

type TProps = {
  large?: boolean;
  animated?: boolean;
  sidebar?: boolean;
  defaultPlaceholder?: string;
  pathname?: string;
};

export default function SearchField({
  large,
  animated,
  sidebar,
  defaultPlaceholder,
  pathname,
}: TProps) {
  const router = useRouter();
  const { search } = router.query;

  const [searchText, setSearchText] = useState(search || "");
  const [focused, setFocused] = useState(false);
  const animatedPlaceholder = usePlaceholderSuggestions();

  useEffect(() => {
    setSearchText(search || "");
  }, [search]);

  useEffect(() => {
    if (router.pathname === "/") {
      setSearchText("");
    }
  }, [router.pathname]);

  function changeSearchText(e) {
    setSearchText(e.target.value);
  }

  function goToList(e) {
    e.preventDefault();
    router.push(
      {
        pathname: pathname || "/autosusados",
        query: searchText ? { search: searchText, page: 1 } : null,
      },
      undefined,
      { shallow: true }
    );
  }

  let placeholder = defaultPlaceholder || "Buscá por marca, modelo y año."; //"Buscá los mejores autos nuevos y usados en 🇨🇷"
  if (animated && !focused) {
    placeholder = animatedPlaceholder;
  } else if (sidebar) {
    placeholder = "Búsqueda";
  }

  return (
    <form className="flex w-full rounded-md shadow-sm" onSubmit={goToList}>
      <div className="relative flex items-stretch flex-grow focus-within:z-10">
        {pathname ? (
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
            {pathname}
          </span>
        ) : null}
        <input
          type="text"
          name="search"
          id="search"
          className={classNames(
            "focus:ring-orange-500 focus:border-orange-500 block w-full rounded-none pl-3 border-gray-300 leading-3",
            large
              ? "py-3 sm:text-lg md:text-2xl font-bold placeholder:font-extralight"
              : "text-input",
            large && animated && !focused
              ? "placeholder-gray-800 font-bold"
              : "",
            large && focused ? "placeholder:font-light" : "",
            pathname ? "" : "rounded-l-md"
          )}
          placeholder={placeholder}
          value={searchText}
          onChange={changeSearchText}
          onFocus={() => setFocused(true)}
        />
      </div>
      <button
        id="search-button"
        type="submit"
        className={classNames(
          "-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-orange-500 font-medium rounded-r-md text-white bg-orange-500 hover:bg-orange-600 hover:border-orange-600 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500",
          large ? "text-lg" : "text-sm"
        )}
      >
        <MagnifyingGlassIcon
          className={classNames("text-white", large ? "h-6 w-6" : "h-5 w-5")}
          aria-hidden="true"
        />
        <span
          className={classNames("hidden", sidebar ? "sm:hidden" : "sm:inline")}
        >
          Buscar
        </span>
      </button>
    </form>
  );
}
